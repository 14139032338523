/* eslint-disable */
import axios from 'axios';
import { baseUrl } from '../../public/static/config';
import CResult from 'co-result';

let header = {}
if (process.env.NODE_ENV === 'development') {
    header.Authorization = "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJjMWZhNDE3YmU1ZmUxMWVjOWY0YTAyNDJhYzExMDAwNC05NDNmMGIxYTU4YzMxMWVjOWY0YTAyNDJhYzExMDAwNC0yNjBmYzNkZDU2OGYxMWVjOWY0YTAyNDJhYzExMDAwNC0xYzc3NTBkNzBkNTE0ZmRlYWQ0NTczNzFkMTdhZDNiYiIsImlhdCI6MTcyNzU3MzMyMiwiZXhwIjoxNzI3NjU5NzIyfQ.33iJ5lvKesUK-gwUcGLpLTLfOKob57wiENbhNQb7sLrYPC-cMiYYGK75Zb-_8aghTTc1IQZXp0tbvVriYK4N3w"
} else {
    header.Authorization = localStorage.getItem('token')
}

const axiosCongig = axios.create({
    baseURL: baseUrl,
    headers: {
        'Access-Control-Allow-Origin': '*',
        "redirect": 'error',
        "Content-Type":  "application/json",
        ...header
    },
});
axiosCongig.defaults.timeout = 60000; //超时时间
// 添加响应拦截器
axiosCongig.interceptors.response.use((response) => {
    // 对响应数据做点什么
    if (response.status === 200) {
        return response.data;
    }
}, function (error) { 
    // 对响应错误做点什么
    return Promise.reject(error);
});

export const axiosCongig1 = (url, method = 'GET', parms = {}, responseType = '',) => {
    return axios.request({
        url: baseUrl + url,
        headers: {
            'Access-Control-Allow-Origin': '*',
            "redirect": 'error',
            //"Content-Type": "application/json;charset=UTF-8",
            ...responseType,
            ...header
        },
        method: method,
        responseType: responseType,
        data: parms,
        timeout: 600000,
        onUploadProgress : (progressEvent) => {
            console.log(progressEvent);
            const progress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
            console.log(progress);
            // Update state here
            // onUploadProgress(progress);
        }
  
    })
}


export const axiosCongig2 = (url, method = 'GET', parms = {}) => axios.request({
    url: baseUrl + url,
    headers: {
        'Access-Control-Allow-Origin': '*',
        "redirect": 'error',
        "Content-Type": "application/x-www-form-urlencoded",
        ...header
    },
    method: method,
    data: parms,
    timeout: 5000
})

export const axiosCongig3 = (url) => axios.request({
    withCredentials: true,
    url: ('http://192.168.1.39:58831/') + url,
    headers: {
        "redirect": 'error',
        "Content-Type": "application/x-www-form-urlencoded",
        ...header,
        'Set-Cookie':'22222'
    },
    method: 'GET',
})


// 添加响应拦截器
axios.interceptors.response.use(response => {
 
    if (response.config.url.indexOf('ssodocking/v1/user') !== -1 || response.config.url.indexOf('gateway/identityaccess-api/ssologin') !== -1 || response.config.url.indexOf('sys/sso/login') !== -1) {
        return response.data;
    } else if (response.data.code === 0 || response.data.code === 200) {
        if (response.data.data) {
            return response.data.data
        } else if (response.data.result) {
            return response.data.result
        } else {
            return response.data;
        }
    }else {
      
        if (response.headers['content-type'] === 'text/plain;charset=utf-8' || response.headers['content-type'] === 'application/octet-stream;charset=utf-8' || response.headers['content-type'] === 'application/x-download') {
            return response;
        }
        CResult({ type: 'ERROR', content: response.data.msg })
        return response.data;
    }
}, function (error) {
    const status = error.response && error.response.status //处理http code 
    if (status === 404) {
        CResult({ type: 'ERROR', content: '无此调用接口' })
     
    } else if (status === 401) {
        CResult({ type: 'ERROR', title: '认证失败,token信息已过期', content: '页面将在2s后跳转至登录页' })
        // setTimeout(() => {
        //     localStorage.removeItem("token");
        //     window.location.replace("/login.html");
        // }, 2000)
     
    } else {
        CResult({ type: 'ERROR', content: error.response.data.message || error.message })
    
    }
});






